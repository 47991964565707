import React, { useEffect, useState, useContext } from 'react';
import { Typography, Box, Paper, Accordion, AccordionSummary, AccordionDetails, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import Event from './Event';
import api from '../../api/axios';
import { useParams, Link as RouterLink } from 'react-router-dom';

import AlertContext from '../../context/AlertContext';

const NotFoundContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
    textAlign: 'center',
  }));

const EventListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
}));

const EventSection = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

const PastEventSection = styled(Paper)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(4),
}));

function PublicEventList() {
  const { setAlert } = useContext(AlertContext);
  const { publicId } = useParams();
  const [events, setEvents] = useState([]);
  const [ groupName, setGroupName ] = useState([]);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const fetchPublicEvents = async () => {
      try {
        const response = await api.get(`/api/public/${publicId}`);
        setEvents(response.data.Events);
        setGroupName(response.data.pubId);
        setNotFound(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
            setNotFound(true);
            setAlert([true, 'Publiczny link nie istnieje!', 'error']);
        }
        console.error('Error fetching public events:', error);
      }
    };

    fetchPublicEvents();
  }, [publicId]);

  const upcomingEvents = events
    .filter((event) => new Date(event.dateTime) > new Date())
    .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));

  const pastEvents = events
    .filter((event) => new Date(event.dateTime) <= new Date())
    .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

    if (notFound) {
        return (
          <NotFoundContainer>
            <Typography variant="h4" gutterBottom>
              Strona nie została znaleziona
            </Typography>
            <Typography variant="body1" gutterBottom>
              Wystąpił błąd podczas ładowania publicznej strony grupy.
            </Typography>
            <Typography variant="body1">
              Wróć do <Link component={RouterLink} to="/">strony głównej</Link>.
            </Typography>
          </NotFoundContainer>
        );
      }

    return (
        <EventListContainer>
            <Typography variant="h3" gutterBottom style={{marginBottom: 40, userSelect: "none"}}>
                <b>{groupName}</b>
            </Typography>
    
          <EventSection elevation={3}>
            <Typography variant="h5" gutterBottom style={{userSelect: "none"}}>
              Nadchodzące wydarzenia
            </Typography>
            {upcomingEvents.map((event) => (
              <Event key={event.ID} event={event} eventReadOnly={true}/>
            ))}
          </EventSection>
    
          <PastEventSection elevation={3}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" style={{userSelect: "none"}}>Wydarzenia z przeszłości</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {pastEvents.map((event) => (
                  <Event key={event.ID} event={event} eventReadOnly={true} />
                ))}
              </AccordionDetails>
            </Accordion>
          </PastEventSection>
        </EventListContainer>
      );
}

export default PublicEventList;