import React, { useEffect, useState, useContext } from 'react';
import { Typography, Button, Box, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import useAuth from '../../hooks/Auth';
import Event from './Event';
import AddEventModal from './AddEventModal';
import api from '../../api/axios';
import AlertContext from '../../context/AlertContext';

const EventListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
}));

const EventSection = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

const PastEventSection = styled(Paper)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(4),
}));

function EventList() {
  const { user, isAuthenticated } = useAuth();
  const [events, setEvents] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const { setAlert } = useContext(AlertContext);

  const fetchEvents = async () => {
    try {
      const response = await api.get('/api/events');
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      setAlert([true, 'Błąd podczas pobierania wydarzeń', 'error']);
    }
  };

  useEffect(() => {
    if (user) {
      fetchEvents();
    }
  }, [user]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const upcomingEvents = events
    .filter((event) => new Date(event.dateTime) > new Date())
    .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
  
  const pastEvents = events
    .filter((event) => new Date(event.dateTime) <= new Date())
    .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));

  return (
    <EventListContainer>
      {isAuthenticated() && (
        <Typography variant="h3" gutterBottom style={{marginBottom: 40, userSelect: "none"}}>
              <b>{user.pubId}</b>
        </Typography>
      )}
      <Button variant="contained" color="primary" onClick={handleOpenAddModal} style={{marginBottom: 25}}>
        Dodaj wydarzenie
      </Button>

      <EventSection elevation={3}>
        <Typography variant="h5" gutterBottom style={{userSelect: "none"}}>
          Nadchodzące wydarzenia
        </Typography>
        {upcomingEvents.map((event) => (
          <Event key={event.ID} event={event} onUpdateEvents={fetchEvents} eventReadOnly={false}/>
        ))}
      </EventSection>

      <PastEventSection elevation={3}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" style={{userSelect: "none"}}>Wydarzenia z przeszłości</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {pastEvents.map((event) => (
              <Event key={event.ID} event={event} onUpdateEvents={fetchEvents} eventReadOnly={false} />
            ))}
          </AccordionDetails>
        </Accordion>
      </PastEventSection>

      <AddEventModal open={openAddModal} onClose={handleCloseAddModal} onUpdateEvents={fetchEvents} />
    </EventListContainer>
  );
}

export default EventList;