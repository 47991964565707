import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { styled } from '@mui/system';
import EventDetails from './EventDetails';

const EventCard = styled(Card)(({ theme, ispast }) => ({
  backgroundColor: ispast ? theme.palette.grey[300] : theme.palette.background.paper,
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const EventDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const EventCountdown = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(1),
}));

const EventIcons = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const EventColorDot = styled('div')(({ color }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor: color,
  marginLeft: '10px',
}));

function Event({ event, onUpdateEvents, eventReadOnly }) {
  const [open, setOpen] = useState(false);
  const [countdown, setCountdown] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const calculateCountdown = () => {
      const eventDate = new Date(event.dateTime);
      const currentDate = new Date();
      const timeDiff = eventDate.getTime() - currentDate.getTime();

      if (timeDiff <= 0) {
        setCountdown('Wydarzenie zakończone');
      } else if (timeDiff < 2 * 24 * 60 * 60 * 1000) {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
        setCountdown(`${hours}h ${minutes}m do wydarzenia`);
      } else {
        const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        setCountdown(`${days} dni do wydarzenia`);
      }
    };

    calculateCountdown();
    const timer = setInterval(calculateCountdown, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [event.dateTime]);

  const isPastEvent = new Date(event.dateTime) <= new Date();

  return (
    <>
      <EventCard onClick={handleOpen} ispast={isPastEvent ? 1 : 0}>
        <EventDetailsContainer>
          <Typography variant="h6" style={{userSelect: "none"}}>{event.title}</Typography>
          <Typography variant="body2" style={{userSelect: "none"}}>{event.dateTime}</Typography>
          <EventCountdown variant="body1" style={{userSelect: "none"}}>{countdown}</EventCountdown>
        </EventDetailsContainer>
        <EventIcons>
          {event.files.length > 0 && <AttachmentIcon />}
          <EventColorDot color={event.color} />
        </EventIcons>
      </EventCard>
      {open && <EventDetails event={event} open={open} onClose={handleClose} onUpdateEvents={onUpdateEvents} isReadOnly={eventReadOnly} />}
    </>
  );
}

export default Event;