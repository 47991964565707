import './App.css';

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';

import About from './components/Pages/About';
import Settings from './components/Pages/Settings';

import EventList from './components/Events/EventList';
import Footer from './components/Footer';
import LoginPage from './components/Auth/LoginPage';
import RequireAuth from './components/Auth/RequireAuth';
import InfoAlert from './components/InfoAlert';
import AlertContext from './context/AlertContext';
import PublicEventList from './components/Events/PublicEventList';

function App() {
  const [alert, setAlert] = useState([false, '', '']);

  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      <Router>
        <Container>
          <Routes>
            <Route path="/" element={
              <RequireAuth>
                  <EventList />
                </RequireAuth>
            } />
            
            <Route path="/about" element={
              <RequireAuth>
                  <About />
                </RequireAuth>
            } />

            <Route path="/settings" element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            } />
            
            <Route path="/login" element={<LoginPage />} />

            <Route path="/public/:publicId" element={<PublicEventList />} />
          </Routes>
          <Footer />
          <InfoAlert alert={alert} setAlert={setAlert} />
        </Container>
      </Router>
    </AlertContext.Provider>
);
}

export default App;
