import React, { useState, useRef, useContext } from 'react';
import { CirclePicker } from 'react-color';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, TextField, Box, Grid, Chip } from '@mui/material';
import { styled } from '@mui/system';
import api from '../../api/axios';
import AlertContext from '../../context/AlertContext';

const EventDetailsContainer = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const EventDetailsSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const EventDetailsSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

const EventDetailsFileList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
}));

const EventDetailsFileItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

function EventDetails({ event, open, onClose, onUpdateEvents, isReadOnly }) {
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const isoString = date.toISOString();
    return isoString.slice(0, 16);
  };

  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(event.title);
  const [date, setDate] = useState(formatDate(event.dateTime));
  const [description, setDescription] = useState(event.description);
  const [files, setFiles] = useState([]);
  const [color, setColor] = useState(event.color);

  const [titleError, setTitleError] = useState('');
  const [dateError, setDateError] = useState('');

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFiles(files);
    setSelectedFiles(files.map(file => file.name));
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const validateTitle = () => {
    if (title.trim().length === 0) {
      setTitleError('Tytuł jest wymagany');
    } else {
      setTitleError('');
    }
  };

  const validateDate = () => {
    if (!date) {
      setDateError('Data jest wymagana');
    } else {
      setDateError('');
    }
  };

  const handleSave = async () => {
    validateTitle();
    validateDate();

    if (titleError === '' && dateError === '' && title.trim().length !== 0 && date) {
      const updatedEvent = {
        ...event,
        title,
        dateTime: date,
        description,
      };

      const formData = new FormData();
      formData.append('title', title);
      formData.append('dateTime', date);
      formData.append('description', description.trim());
      formData.append('color', color);
      files.forEach((file) => {
        formData.append('files', file);
      });

      try {
        await api.put(`/api/events/${event.ID}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setEditMode(false);
        onUpdateEvents();
        onClose();
        setAlert([true, 'Pomyślnie edytowano wydarzenie!', 'success']);
      } catch (error) {
        console.error('Error updating event:', error);
        setAlert([true, 'Wystąpił błąd podczas edycji eventu!', 'error']);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/api/events/${event.ID}`);
      onClose();
      navigate('/');
      onUpdateEvents();
      setAlert([true, 'Pomyślnie usunięto Wydarzenie!', 'success']);
    } catch (error) {
      console.error('Error deleting event:', error);
      setAlert([true, 'Wystąpił błąd podczas usuwania eventu!', 'error']);
    }
  };
  
  const handleDeleteFile = async (fileId) => {
    try {
      await api.delete(`/api/events/${event.ID}/files/${fileId}`);
      onUpdateEvents();
      setAlert([true, 'Plik usunięty!', 'success']);
    } catch (error) {
      console.error('Error deleting file:', error);
      setAlert([true, 'Wystąpił błąd podczas usuwania pliku!', 'error']);
    }
  };

  const handleDownload = (file) => {
    api
      .get(`/api/events/files/${file.uniqueFilename}`, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setAlert([true, 'Pobrano załącznik!', 'success']);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
        setAlert([true, 'Wystąpił błąd podczas pobierania załącznika!', 'error']);
      });
  };

  const handleAddFiles = () => {
    fileInputRef.current.click();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{event.title}</DialogTitle>
      <EventDetailsContainer>
        <EventDetailsSection>
          <EventDetailsSectionTitle variant="subtitle1">Data:</EventDetailsSectionTitle>
          <Typography variant="body1">{event.dateTime}</Typography>
        </EventDetailsSection>

        <EventDetailsSection>
          <EventDetailsSectionTitle variant="subtitle1">Opis:</EventDetailsSectionTitle>
          <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>{event.description}</Typography>
        </EventDetailsSection>

        {event.files.length > 0 && (
          <EventDetailsSection>
            <EventDetailsSectionTitle variant="subtitle1">Załączone pliki:</EventDetailsSectionTitle>
            <EventDetailsFileList>
              {event.files.map((file) => (
                <EventDetailsFileItem container key={file.uniqueFilename} alignItems="center" spacing={1}>
                  <Grid item xs>
                    {file.filename}
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" size="small" onClick={() => handleDownload(file)}>
                      Pobierz
                    </Button>
                  </Grid>
                  {isReadOnly == false ? (
                  <Grid item>
                    <Button variant="outlined" size="small" color="secondary" onClick={() => handleDeleteFile(file.ID)}>
                      Usuń
                    </Button>
                  </Grid>
                  ) : (<></>)}
                </EventDetailsFileItem>
              ))}
            </EventDetailsFileList>
          </EventDetailsSection>
        )}

        {editMode && (
          <EventDetailsSection>
            <EventDetailsSectionTitle variant="subtitle1">Edycja wydarzenia:</EventDetailsSectionTitle>
            <TextField
              label="Tytuł"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              margin="normal"
              onBlur={validateTitle}
              error={titleError !== ''}
              helperText={titleError}
            />
            <TextField
              label="Data"
              type="datetime-local"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={validateDate}
              error={dateError !== ''}
              helperText={dateError}
            />
            <TextField
              label="Opis"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              minRows={4}
              maxRows={8}
              style={{marginBottom: 30}}
            />
            <CirclePicker color={color} onChange={(newColor) => setColor(newColor.hex)}/>
            <Button variant="contained" color="primary" onClick={handleAddFiles} style={{marginTop: 30}}>
              Dodaj pliki
            </Button>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
            <Box mt={1}>
              {selectedFiles.map(fileName => (
                <Chip key={fileName} label={fileName} onDelete={() => {
                  setSelectedFiles(selectedFiles.filter(file => file !== fileName));
                  setFiles(files.filter(file => file.name !== fileName));
                }} style={{ marginRight: '4px' }} />
              ))}
            </Box>
          </EventDetailsSection>
        )}
      </EventDetailsContainer>
      {isReadOnly == false ? (
            <DialogActions>
            {editMode ? (
              <Button variant="contained" color="primary" onClick={handleSave} disabled={title.trim().length === 0 || !date}>
                Zapisz
              </Button>
            ) : (
              <>
                <Button variant="contained" color="primary" onClick={handleEdit}>
                  Edytuj
                </Button>
                <Button variant="contained" color="secondary" onClick={handleDelete}>
                  Usuń
                </Button>
              </>
            )}
            <Button onClick={onClose}>Zamknij</Button>
          </DialogActions>
    ) : (<></>)}  

    </Dialog>
  );
}

export default EventDetails;