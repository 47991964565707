import React from 'react';
import { Box, Button, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import useAuth from '../hooks/Auth';

function Footer() {
  const { isAuthenticated, logout, user } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Typography variant="body2" color="text.secondary" align="center">
        <Link component={RouterLink} to="/" color="inherit" sx={{ mr: 2 }}>
          SBox
        </Link>
        {isAuthenticated() && (
          <Link component={RouterLink} to="/about" color="inherit" sx={{ mr: 2 }}>
            O SBox
          </Link>
        )}
        {isAuthenticated() && (
          <Link component={RouterLink} to="/settings" color="inherit">
            Ustawienia
          </Link>
        )}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {'© '}
        {new Date().getFullYear()}
        {' SBox. Fabian Zwoliński'}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
      {isAuthenticated() && (
        <>
          <Typography variant="body2">Zalogowany jako: {user.email}</Typography>
          <Typography variant="body2">Należy do grupy: {user.pubId}</Typography>
          <Button variant="contained" color="secondary" onClick={handleLogout}>
            Wyloguj
          </Button>
        </>
        )}
      </Typography>
    </Box>
  );
}

export default Footer;